
.flex-column {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  > * {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}


@mixin box-shadow-2($args1, $args2) {
    -webkit-box-shadow: $args1, $args2;
    -moz-box-shadow: $args1, $args2;
    box-shadow: $args1, $args2;
}


// Z-levels

.z-depth-1, .floating-action-button, .card {
    @include box-shadow-2(0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12));
}
.z-depth-1-half{
    @include box-shadow-2(0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15));
}
.z-depth-2, .z-depth-1:hover, .floating-action-button:hover, .card:hover {
    @include box-shadow-2(0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-3, .z-depth-2:hover{
    @include box-shadow-2(0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-4, .z-depth-3:hover{
    @include box-shadow-2(0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21));
}
.z-depth-5, .z-depth-4:hover{
    @include box-shadow-2(0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22));
}

[class*="z-depth-"] {
  transition: all 0.25s ease-in-out;
}

.page-title, .row .page-title, .row .row.page-title {
  text-align: center;
  padding: 2rem 0.5rem 1rem;
  color: $primary-color-dark;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 3rem;
  @include phone {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    font-size: 2rem;
  }
}

.offcanvas-title {
  color: $primary-color-dark;
  font-size: 1.4rem;
  line-height: 3rem;
  border-bottom: solid 3px #fff;
}

.off-canvas {
  .offcanvas-menu-list {
    li {
      border-bottom: solid 3px #fff;
      a {
        line-height: 3rem;
      }
    }

    li.has-submenu {

    }

    .submenu {
      li:first-child {
        border-top: solid 1px #fff;
      }
      li:last-child {
        border-bottom: none;
      }
      li:only-child {
        border-top: solid 1px #fff;
      }
      a {
        padding: 0;
        text-indent: 1rem;
        line-height: 2.4rem;
      }
    }
  }
}

img {
  max-width: 100%;
}

button:focus {outline:0;}

// Dividers

.divider {
  height: 1px;
  overflow: hidden;
  background-color: color("grey", "lighten-2");
}

.expand {
  width: 100%;
}

//  Blockquote

blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid color("red", "lighten-3");
}

.content-container, .content-container.row, .row .content-container {
  width: 60%;
  margin: auto;

  @include tablet {
    width: 80%;
  }
  @include phone {
    width: 95%;
  }
}

.section-title {
  margin: auto;
  text-align: center;
  font-size: 2rem;
  color: #666;
  padding: 1rem;
}

.reveal {
  .section-title {
    text-align: center;
    display: block;
  }
}

.inline-list > li {
  display: inline-block;
}

.fullwidth {
  max-width: none;
}

.form-item.row {
	margin: 0;
  margin-bottom: 1rem;
}

.alert-box {
  text-align: center;
  font-size: 1rem;
}


// =============== cards ================

.card {
  position: relative;
  overflow: hidden;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  transition: box-shadow .25s;
  min-width: 180px;
  border-radius: 2px; }
  .card .card-title {
    color: #fff;
    font-size: 1.2rem;
	width: 100%;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0+0,0.65+100 */
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    font-weight: 300; }
    .card .card-title.activator {
      cursor: pointer; }
  .card.small, .card.medium, .card.large {
    position: relative; }
    .card.small .card-image, .card.medium .card-image, .card.large .card-image {
      overflow: hidden; }
    .card.small .card-content, .card.medium .card-content, .card.large .card-content {
      overflow: hidden; }
    .card.small .card-action, .card.medium .card-action, .card.large .card-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
  .card.small {
    height: 300px; }
    .card.small .card-image {
      height: 150px; }
    .card.small .card-content {
      height: 150px; }
  .card.medium {
    height: 400px; }
    .card.medium .card-image {
      height: 250px; }
    .card.medium .card-content {
      height: 150px; }
  .card.large {
    height: 500px; }
    .card.large .card-image {
      height: 330px; }
    .card.large .card-content {
      height: 170px; }
  .card .card-image {
    position: relative; }
    .card .card-image img, .card .card-image .image {
      border-radius: 2px 2px 0 0;
      position: relative;
  		height: 240px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%; }
    .card .card-image .card-title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0.5rem; }
  .card .card-content {
    padding: 0.6rem;
    border-radius: 0 0 2px 2px; }
    .card .card-content p {
      margin: 0;
      color: inherit; }
    .card .card-content .card-title {
      line-height: 48px; }
  .card .card-action {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 0.6rem; }
    .card .card-action a {
      color: transparentize($anchor-color, 0.6);
      margin-right: 20px;
      text-transform: uppercase; }
      .card .card-action a:hover {
        color: $anchor-color; }
  .card .card-reveal {
    padding: 20px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    top: 100%;
    height: 100%;
    z-index: 1;
    display: none; }
    .card .card-reveal .card-title {
      cursor: pointer;
      display: block; }

header, main, footer {
  display: block;
  margin: 0;
  width: 100%;
}

.collections {
  .item {
    padding: 0.5rem 0.5rem 0.5rem 90px;
    // line-height: 80px;
    border-bottom: solid 1px #eee;
  }
}


.collections-with-image {
  width: 100%;
  li {
    &:nth-last-child(1) {
      .item {
        // border-bottom: solid 1px #eee;
      }
    }
  }
  .item {
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 170px;
    height: 140px;
    // margin-bottom: 1rem;
    // border-top: solid 1px #eee;
    // border-right: solid 1px #eee;
    // border-left: solid 1px #eee;
    transition: all 0.25s ease-in-out;
    overflow: hidden;

    &:hover {
      // background-color: lighten($primary-color, 55%);
    }

    @include phone {
      padding: 0.5rem 0.5rem 0.5rem 110px;
      height: 80px;
    }

    &.vac {
      align-items: flex-start;
    }

    .image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 150px;
      height: 100px;
      @include phone {
        width: 90px;
        height: 60px;
        // top: 10px;
        // left: 10px;
      }
      img {
        height: 100px;
        width:auto;
        max-width: 100%;
        border-radius: 4px;
        @include phone {
          // width: 90px;
          height: 60px;
        }
      }
    }
    .title {
      // line-height: 60px;
      // text-transform: uppercase;
      // color: $primary-color;
      // height: 1.2em;
      a {
        display: block;
        text-align: left;
      }
      h3 {
        margin: 0;
        padding-left: 0.25rem;
        font-size: 1.4rem;
        @include phone {
          font-size: 1rem;
        }
      }
      @include phone {
        line-height: normal;
        font-size: 0.875rem;
      }
    }
    .timestamp {
      @include phone {
        position: absolute;
        bottom: 0.25rem;
        right: 0.5rem;
      }
    }
    .content {
      color: #999;
      font-size: 0.875rem;
      line-height: 1rem;
      padding: 0.25rem;
    }
  }
}


.checkbox-wrapper input[type="checkbox"], .radio-wrapper input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: none;

  & + label {
    background-color: #ccc;
    color: #fff;
    border: solid 1px #666;
    border-radius: 6px;
    line-height: 2.315rem;
    padding: 0 2rem;
    margin: 0.5rem;
    cursor: pointer;
    width: 100%;
    text-align: center;

    @include phone {
      padding: 0 1.5rem;
    }
    &.form-actions {
      width: 100%;
      border-radius: 2px;
      text-align: center;
      margin: 1rem auto;
    }
  }

  &:checked + label {
    background-color: $success-color;
    border-color: darken($success-color, 10%);

    
  }
}

.birthday-picker {
  select {
    width: 33.33%;
    display: inline-block;
    margin: 0;
  }
}

.form-item {
  margin-bottom: 1rem;
  position: relative;
}

.form-actions {
  button, a {
    width: 100%;
  }
}




html, body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

body {
  // background:transparent url('../img/bg-blue.jpg');
}

.webpage-container {
  min-height: 100vh;
  height: 100%;
  align-content: space-between;

}

.fullwidth {
    max-width: none;
}


.captcha {
  margin: 2rem auto;
  div {
    margin-left: auto;
    margin-right: auto;
  }
}


h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

.name {
  img {
    width: auto;
  }
}

.text-center {
  text-align: center;
}

.section-title {
  // color: $primary-text-color;
}

a {
  color: $anchor-color;
  &:not(.button, .naked-button) {
    // border-bottom: solid 2px #fff;
    &:hover {
      border-color: $anchor-color;
    }
  }
  &:hover, &:focus, &:target {
    color: $anchor-color-hover;
  }

}

.button, button, a, i, .list-actions {
  transition: all 0.25s ease-in-out;
}

.section-divider {
  height: 6rem;
  width: 60%;
  margin: auto;
}

.title {
  text-transform: uppercase;
  font-family: $header-font-family;
}

.excerpt {
  font-size: 0.875rem;
  color: #666;
}


.no-stuffs {
  padding: 3rem 1rem;
  .alert-box {
    padding: 3rem 1rem;
  }
}

.block-title {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
}

.image {
  text-align: center;
}

.date-box-wrapper {
  max-width: 4rem;
}

span, small {
  &.error {
    border-radius: 0 0 6px 6px;
  }
}

.date-box {
  width: auto;
  display: block;
  border: solid 1px;
  border-radius: 6px;
  text-align: center;


  .date {
    font-size: 0.875rem;
    position: relative;
    top: 0.875rem;
    display: block;
  }
  .month {
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
  }
  .year {
    font-size: 0.875rem;
    position: relative;
    top: -1rem;
    display: block;
  }
}



.card-action {
  i {
    color: $accent-color;
  }
}

form button {
  padding: 1rem 2rem;
  background-color: $anchor-color;

}

.radius {
  border-radius: 4px;
}

.page-login {
  .logo-login i {
    font-size: 6rem;
    color: $primary-color;
  }

  input {
    padding-left: 2.8rem;
  }

  .logo-login {
    padding: 1rem;
  }
  button {
    border: solid 1px $primary-color-dark;
  }
  .login-else {
    padding-top: 0.75rem;
  }
  .form-item {
    i {
      position: absolute;
      left: 0.75rem;
      top: 0.75rem;
      color: #666;
    }
  }
}



.header {
  // position: absolute;
  // top: 0;
  z-index: 99;
  width: 100%;
  // @include phone {
  //   position: relative;
  // }
}


.list-blogs {
  .item {
    border-bottom: solid 1px #eee;

  }
  .title {
    text-transform: capitalize;
    // height: 2rem;
    overflow: hidden;
    opacity: 0.875;
  }
  .excerpt {
    font-style: italic;
    color: #999;
    font-size: 0.875rem;
    max-height: 1.6rem;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .timestamp {
    @extend .excerpt;
    text-align: right;
    position: absolute;
    right: 0.65rem;
    bottom: 0.25rem;
  }
}

.blog-details {
  .page-title {
    font-size: 2rem;
    // @include phone {
    //   font-size: 1.6rem;
    // }
  }
  .blog-content {
    padding-top: 60px;
  }
}

.slick-centered {
  position: relative;
  margin: 2rem auto;
  img {
    max-height: 480px;
  }
}

.slick-slider {
  margin-bottom: 0;
}

.slideshow-nav {
  .slick-slide {
    border: solid 4px #fff;
   cursor: pointer;
  }
  .slick-slide:not(.slick-current) {
    opacity: 0.4;
  }
  
}

.list-blogs-latest {
  li {
    margin-bottom: 1rem;
    background-color: #fff;
  }
}


.page-blog-list {
  .list-blogs {
    .item {
      padding: 1.5rem 1rem 1rem 90px;
      height: 120px;
      .image {
        top: 30px;
      }
    }
  }
}


.slideshow {
  position: relative;
  margin-bottom: 30px;
  & > li {
    text-align: center;
  }
  img {
    margin: auto;
  }
}

.hero {
  margin-bottom: 2rem;

}

.hero-slideshow {
  text-align: center;
}


.slick-slide img {
  margin: auto;
}


.slick-prev:before, .slick-next:before {
  color: $anchor-color;
  font-size: 60px;
}



.slick-prev {
  left: -60px;
  opacity: 0;
  transition: all 0.25s ease;
}


.slideshow:hover .slick-prev {
  left: 0;
  opacity: 1;
  z-index: 1;
}

.slick-next {
  right: -60px;
  opacity: 0;
  transition: all 0.25s ease;
  
}
.slideshow:hover .slick-next {
  right: 0;
  opacity: 1;
}

.slick-prev, .slick-next {
  width: 60px;
  height: 60px;
}


.hero-slideshow {
  margin-bottom: 0;
}

.hero-slideshow-nav {
  max-width: 360px;
  margin: auto;
  text-align: center;
  .item {
    width: 60px;
    height: 60px;
    border: solid 2px #fff;
  }
}



.slideshow-nav, .slick-centered {
  .slick-slide {
    opacity: 0.3;
    transition-duration: 0.3s;
    &.slick-current {
      opacity: 1;
      &:only-child {
        position: relative;
        left: -120px;
      }
    }
  }
}



.action-buttons {
  max-width: 480px;
  height: 90px;
  text-align: center;
  margin: 1rem auto;
  .action-button-next {
    // float: right;
    // padding: 1rem 2rem;
    border: solid 1px $anchor-color;
  }
  .next:hover, .next:focus, .next:target {
    background-color: $success-color;
  }

}

.zebra-list {
  & > li:nth-child(even) {
    background-color: #efefef;
  }
}

input.required {
  border-color: $alert-color;
}

fieldset {
  border: solid 1px $medium-gray;
  padding: 1.6rem;
  legend {
    padding: 0.5rem 1rem;
    background-color: #fff;
    font-weight: 400;
    font-size: 1.1rem;
  }
}

.form-inline-actions {
  text-align: center;
}