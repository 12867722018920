
@import '../bower_components/motion-ui/motion-ui';

.slick-slide .auto-size {
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.pagination .current {
    padding: 0 !important;
    background: #a62677;
    color: #fff;
    cursor: default;
    a {
        color: #fff;
    }
}

.page-title {
    font-family: $header-font-family;
}

.slide-home {
    object-fit: cover;
    object-position: center center;
    width: 100%;
}

.slideshow {
    position: relative;
    margin-bottom: 0;
    .box-arrow {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        .next-arrow, .prev-arrow {
            padding: 1rem;
        }
    }
}

.next-arrow > .ion-chevron-left, .prev-arrow > .ion-chevron-right {
    font-size: 4em;
    color: rgba(255, 255, 255, 0.7);
    text-shadow: 0 0 10px #000;
    &:hover {
        text-shadow: 0 0 20px #000;
        color: rgba(255, 255, 255, 0.8);
    }
}

.page {
    padding-bottom: 0 !important;
}

// .box-logo-title {
// height: 36px;
.logo-title {
    font-family: $header-font-family;
    font-size: 20px;
    text-align: center;
    position: relative;
    opacity: 0;
    text-transform: uppercase;
}

// }

.thumbnail {
  width: 100%;
  img {
      width: 100%;
  }
}

.home-reservation-belt {
    z-index: 2 !important;
}

.orbit-previous, .orbit-next {
    font-size: 2em;
    top: 45%;
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%);
    z-index: 1;
}

#home {
  .fieldset legend {
    background: transparent;
  }
}

// .bouncing-logo {
//  position: relative;
//  animation: bounce 10s infinite;
//  animation-timing-function: cubic-bezier(0, 1.8, 1, 1.8);
// }

// .ri-logo {
//  animation-delay: 0.3s;
// }

// .va-logo {
//  animation-delay: 0.6s;
// }

// .vi-logo {
//  animation-delay: 0.9s;
// }

// @-webkit-keyframes bounce {
//   0% {
//  -webkit-transform:translateY(0);
//   }
//   2% {
//  -webkit-transform:translateY(-10%);
//   }
//   4% {
//  -webkit-transform:translateY(0);
//   }
//   100% {
//  -webkit-transform:translateY(0);
//   }
// }

.fancybox-lock body {
    overflow: visible !important;
}

.preloader-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('/static/rivavi/front/img/loading.gif') #FFF no-repeat center center;
  color: $gold;
  opacity: 1;
  // transition: all 0.5s ease-out;
}


#home {
    .section-welcome {
        .welcome {
            padding: 1rem 4rem;
            @include phone {
              padding: 1rem 2rem 1rem 1.5rem;
            }
            @include tablet {
              padding: 0 2rem 2rem 2rem;
            }
        }
        .welcome-content {
            padding: 0 4rem 3rem 3rem;
            font-size: 0.875rem;
            @include phone {
              padding: 0 2rem 1rem 1rem;
            }
            @include tablet {
              padding: 0 2rem 1rem 1rem;
            }
        }
        .promo-panels-wrapper {
            margin: 0 4rem 3rem 3rem;
            @include phone {
                margin: 0 auto 3rem;
            }
        }
    }
    .hotel-facts {
        margin-bottom: 1rem;
        li {
            padding: 0.4rem;
            font-size: 14px;
        }
    }
    .collections-with-image {
        .item {
            // padding: 1.5rem 0 1.5rem 155px;
            .image {
                // width: 120px;
                // height: 70px;
                // img {
                //     height: 70px;
                // }
            }
        }
    }
}

#event {
    .media-object {
        .media-object-section {
            h5 {
                font-size: 1.075rem;
                margin-bottom: 0.5rem;
            }
            h6 {
                font-family: inherit;
                font-size: 0.775rem;
            }
            p, li {
                font-size: 0.675rem;
            }
        }
    }
}

.gallery-masonry {
  text-align: center;
  margin: auto;
  .gallery-item, .grid-sizer {
    width: 20%;
  }
  .gallery-item {
    padding: 0.5%;
    img {
      width: 100%;
    }
    .thumbnail {
      margin-bottom: 0.25%;
      border: 0;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      &:hover {
        box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      }
    }
  }
  .gutter-sizer {
    width: 20%;
  }
  &.home-masonry {
    .gallery-item, .grid-sizer {
      width: 30%;
    }
  }
}

.gmap {
  position: relative;
  .openmap {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.page-gallery-list {
  .pagination {
    margin-top: 1rem;
  }
}

#home {
  .gallery-list-wrapper {
    padding: 1rem 2.8rem;
    @include phone {
      padding: 1;
    }
  }
  .button.green {
    background: #05b34d;
    &:hover {
      background: #049540;
    }
  }
}

ul.accordion {
  padding-top: 0 !important;
  background: transparent;
    max-width: 540px;

  @include phone {
    max-width: none;
  }
}

.accordion-title {
  color: #fff;
  background-color: rgba(50,0,0, 0.4);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    background-color: rgba(50,0,0, 0.6);
    color: #fff;
  }
  &:active {
    background-color: rgba(50,0,0, 0.6);
    color: #fff;
  }
  &:focus {
    background-color: rgba(50,0,0, 0.6);
    color: #fff;
  }
}

input[type='text']#input-coupon {
  background-color: transparent;
}

#input-coupon {
  margin-top: 1rem;
}

#extra-bed, #extra-breakfast, #airport, #dinner {
  margin-top: 1rem;
}

.coupon {
  margin-top: 1rem;
  a {
    color: #fff;
    font-weight: bold;
  }
}

.accordion-content {
  background: transparent;
  .checkbox-1 {
    margin-bottom: 1rem;
  }
}

.page-gallery-list {
  button {
    margin-bottom: 2rem;
  }
}

ul.orbit-container {
  @include desktop {
    // height: 0 !important;
  }
}


/*================================================ checkbox 1 ========================*/


.checkbox-1 input[type=checkbox], .checkbox-1 input[type=radio]  {
  display: none;

}

.checkbox-1 input[type=checkbox] + label, .checkbox-1 input[type=radio] + label {
  width: 100%;
  text-align: center;
  margin-left: 0;
}

.checkbox-1 input[type=checkbox] + label span, .checkbox-1 input[type=radio] + label span {
  display: inline-block;
  width: 100%;
  height: 2.315rem;
  vertical-align: middle;
  
  cursor: pointer;
  padding: 0.5rem 0.4rem;
  
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ddd;
  color: #999;
  border-radius: 4px;
}

.checkbox-1 input[type=checkbox]:checked + label span, .checkbox-1 input[type=radio]:checked + label span {
  background-color: $green;
  color: #fff;
}

.gmap {
  background: url('/static/rivavi/front/img/map.jpg') no-repeat center center;
}

.load-more-button {
  padding: 1rem 2rem;
  font-size: 1rem;
  // background: $green;
}

.page-reservation {
  .checkbox-1 {
    margin-bottom: 1rem;
  }
}

.promo-code {
  // top: -1.1rem !important;
}

.section-location {
  padding: 3rem 2rem;
  @include tablet-down {
    padding: 3rem 1rem;
  }
}