.logo {
	margin: auto 1rem auto auto;
  padding: 0 1rem;
	@include phone {
		max-width: 150px;
	}
	img {
		max-width: 90px !important;
		display: block;
		max-width: 100%;
	}
}

.title-bar {
	@include tablet-down {
		padding-left: 0;
		padding-top: 1rem;
	}
}

.title-bar-title {
	// font-family: $header-font-family;
	text-transform: uppercase;
  .logo {
    margin: auto auto auto 1rem;

    @include tablet {
      max-width: 150px;
    }
    @include tablet-down {
    	margin-left: 0;
    }
    .logo-title {
      color: #fff;
      @include tablet {
        font-size: 20px;
      }
      @include phone {
        font-size: 16px;
        
      }
    }
  }
}

.text-right {
	text-align: right;
}

.form-error-invert {
	color: #fff;
}

.vac {
	display: flex !important;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;
}

.book-reserve {
	text-transform: uppercase;
  .contact-wrapper {
    a {
      font-size: 1.2rem;
      font-weight: 700; 
    }
  }
}

.naked-button {
	padding: 1rem 1.5rem;
	border: solid 3px $primary-color;
	border-radius: 6px;
	font-weight: bold;
	// background: #cb60b3; /* Old browsers */
	// background: -moz-linear-gradient(top,  hsla(313,51%,58%,1) 0%, hsla(316,81%,38%,1) 50%, hsla(320,69%,58%,1) 100%); /* FF3.6-15 */
	// background: -webkit-linear-gradient(top,  hsla(313,51%,58%,1) 0%,hsla(316,81%,38%,1) 50%,hsla(320,69%,58%,1) 100%); /* Chrome10-25,Safari5.1-6 */
	// background: linear-gradient(to bottom,  hsla(313,51%,58%,1) 0%,hsla(316,81%,38%,1) 50%,hsla(320,69%,58%,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb60b3', endColorstr='#de47ac',GradientType=0 ); /* IE6-9 */
	// color: #fff;
	color: $primary-color;
	&:hover {
		border-color: $accent-color;
		color: $accent-color;
	}
	&.invert {
		border-color: #fff;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		&:hover {
			border-color: #fefefe;
			color: #fefefe;
		}
	}
	&.icon-button {
		background-color: transparent;
		border: none;
		color: $accent-color;
	}
}

.local-info-belt {
	background-color: $primary-color;
	color: #fff;
	padding: 0.25rem;
	font-size: 0.9rem;
	transition: all 0.25s ease-in;
	&:hover {
		background-color: $accent-color;
	}
}

.nav-belt-1 {
	background-color: $green;
}
.page-home {
	position: relative;
}
.home-reservation-belt {
	position: absolute;
	width: 100%;
	z-index: 1;
	font-family: "Helvetica", "Open Sans", Arial, sans-serif;
  .inilabel {
    font-weight: 700;
    text-transform: uppercase;
  }
	* {
		transition: all 0.2s ease-in;
	}
	background-color: transparentize($red, 0.15);
	padding: 0.6rem 1rem 0;
	color: #fff;
	label, input, textarea, select {
		color: #fff;
		cursor: pointer;
	}
	.form-extension-toggler {
		visibility: hidden;
		opacity: 0;
		height: 1px;
		i {
			font-size: 3rem;
			color: #fff;
		}
	}
	form.ng-dirty {
		padding-top: 1.2rem;
		padding-bottom: 2rem;
		> ul:not(:first-child) {
			padding-top: 1.2rem;
		}
		.floating-input-wrapper {
			margin-top: 1.2rem;
		}
		.form-inline-actions {
			button {
				// background-color: $green;
				// font-size: 8rem;
				// text-shadow: 0 0 10px #000;
				&:hover {
					// background-color: lighten($green, 5%);
				// text-shadow: 0 0 20px #000;

				}
			}
		}
		.form-extension-toggler {
			visibility: visible;
			opacity: 1;
			height: 2rem;
			align-items: center;
			position: relative;
			top: 1rem;
		}

		.form-extension {
			& + .form-extension-toggler {
				i.contract-form {
					opacity: 1;
					width: auto;
					display: block;
				}
				i.expand-form {
					display:none;
					opacity: 0;
					width: 1px;
				}
			}
		}

		.form-extension.contracted {
			max-height: 1px;
			padding-top: 1rem;
			overflow: hidden;
			padding-bottom: 0;
			& + .form-extension-toggler {
				i.contract-form {
					display:none;
					opacity: 0;
					width: 1px;
				}
				i.expand-form {
					opacity: 1;
					width: auto;
					display: block;
				}
			}
		}
	}
	.room-name {
		text-transform: uppercase;
		font-weight: 700;
	}
	.room-list {
		input, select {
			color: #666 !important;
		}
	}

}

.form-reserve {
	li {
		padding-bottom: 1rem;
	}
	.inilabel {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 1.2rem;
	}

}
.room-list {
	> div {
		margin-bottom: 1rem;
	}
}

.room-list-item {
	padding: 1rem;
}

.top-bar {
	// background-color: $primary-color-dark;
  padding: 0;
	li:not(.logo) {
		a {
			font-size: 0.875rem;
			text-transform: uppercase;
			font-weight: 700;
			// font-family: $header-font-family;
			// font-size: 1.2rem;
			border-bottom: solid 4px $primary-color-dark;
			&:hover, &:focus, &:active {
				color: $white;
				border-color: $white;
			}
			@include phone {
				border-bottom: solid 1px #ccc;
			}
		}
	}
	li.reserve-now {
		margin: auto;
		@include tablet-down {
			margin: 0.5rem;
		}
		a {
			word-break: keep-all;
			display: block;
			border-color: $primary-color;
			width: 150px;
			&:hover {
				border-color: #fff;
			}
		}
	}
	.logo a {
		border-bottom: none;
	}
	.meetings {
		a {
			width: 190px;
			@include tablet-down {
				width: 100%;
			}
		}
	}
	.contact {
		a {
			width: 135px;
			@include tablet-down {
				width: 100%;
			}
		}
	}
}

.slick-prev:before, .slick-next:before {
	color: #333;
}

.input-group {
	.input-group-label {
		width: 120px;
	}
	.icon-button {
		font-size: 2.4rem;
		padding: 0 1rem;
	}
}

.block-title.block-bg {
	padding: 1rem 1rem 0.5rem;;
  margin: 0;
  background-color: $primary-color;
  color: #fff;
}

.contacts-wrapper {
	margin-bottom: 1rem;
	> div {
		padding-bottom: 0.75rem;
		text-align: center;
		a {
			font-size: 1.4rem;
			font-weight: 700;
		}

	}
	.online-reserve a {
		display: block;
		width: auto;
		margin-top: 1rem;
	}
	.contact-label {
		font-weight: $global-weight-normal;
	}
}

.reveal-contacts-wrapper {
  > div {
    padding-bottom: 1rem;
  }
  a {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.list-main-menu {
	li:first-child {
		a {
			@include phone {
				border-bottom: none;
				padding: 2rem 0;
			}
		}
	}
}

.page {
	padding-bottom: 5rem;
}

.page-contact {
	.contacts-wrapper {
		> div {
			text-align: left;
		}
    i {
      width: 24px;
      margin-right: 0.4rem;
      display: inline-block;
      text-align: right;
    }
	}
	.align-spaced {
		> div {
			padding: 2rem;
		}
	}
	form {
		margin-top: 1.3rem;

	}
}

.contact-links {
	padding-bottom: 1rem;
}

.reveal-content {
	padding: 0 1rem 2rem;
	> .row {
		margin-bottom: 1rem;
	}
}

.object-content {
	margin: 1rem auto;
	* {
		max-width: 100%;
	}
}

.roomtype-rate {
	padding: 1rem;
}

footer {
	a {
		padding: 0.25rem 0.5rem;
		color: #fff;
		text-decoration: none;
		border: none !important;
		&:hover {
			color: #fff;
		}
	}
	.socials {
    @include phone {
      border-bottom: solid 1px #fff;
      padding-bottom: 0.5rem;
      margin-bottom: 0.4rem;
    }
		> li {
      float: right;
      display: inline-block;
      @include phone {
        &.page-links {
          float: left;
        }
      }
		}
	}
  .copyright {
    @include phone {
      text-align: center;
    }
  }
}

.footer-bottom {
	background-color: $primary-color-dark;
	color: #fff;
	padding: 0.25rem;
}

.roomtype-list {
	.rate {
		padding: 0.25rem;
		text-align: right;
		color: $red;
		font-size: 0.75rem;
	}
	.link-more {
		margin-top: 0.75rem;
    width: 100%;
    @include tablet {
    	margin-top: 0.25rem;
    }
    @include phone {
    	margin-top: 0;
    }
	}
}

.roomtype-content {
  padding: 2rem 1rem 3rem;
}

.link-more {
	margin-top: 0.5rem;
	@include phone {
		margin-top: 0.25rem;
	}
	a {
		padding: 0.15rem 0.5rem;
		text-transform: uppercase;
		color: #fff;
		background-color: $primary-color;
		font-size: 0.7rem;
		border-radius: 3px;
	}
}


.reserve-now {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.footer-block {
	border: solid 4px;
	border-radius: 6px;
	font-size: 1.2rem;
	margin-bottom: 1rem;
	.button {
		align-items: center;
		color: #fff;
		margin-top: 1rem;
		padding: 0.6rem;
		font-size: 1.2rem;
		border-radius: 4px;
		margin-bottom: 0;
		i {
			padding-left: 0.5rem;
			font-size: 2rem;
			vertical-align: middle;
		}
	}

	.block-title {
		font-size: 1.8rem;
		padding-bottom: 0.6rem;
	}

	&.red {
		border-color: $red;
		color: $red;
		button, .icon-button {
			background-color: $red;
			color: #fff;

		}
		a {
			color: $red;
		}
	}
	&.purple {
		border-color: $purple;
		color: $purple;
	}
	&.green {
		border-color: $green;
		color: darken($green, 10%);
	}
	a {
			font-weight: 400;
			font-size: 1rem;
	}

	&.connect {
		border-color: transparent;
		i {
			font-size: 3rem;
		}
	}
}

.floating-input-wrapper {
	

	position: relative;
	input, input:focus, input:-webkit-autofill, input.floating-input-field {
		background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-bottom: solid 2px #fff;
    color: #fff;
	}
	
	[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
		background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-bottom: solid 2px #fff;
    color: #fff;
	}
	.floating-input-label {
		position: absolute;
		top: 0.4rem;
		height:1px;
	}
	span.ng-dirty + .floating-input-label, input.ng-dirty + .floating-input-label, textarea.ng-dirty + .floating-input-label {
		top: -1.1rem;
		font-size: 0.875rem;
	}

  .promo-code {
    top: -1.1rem;
  }
}

textarea.autosize {
	min-height: 2.15rem;
	overflow-x: hidden;
	// max-height: 10rem;
}

.page-reservation {
	.content-container {
		max-width: 640px;
	}
}

.section-promo-belt {
	border-top: solid 8px $green;
	background-color: #fff;
	color: $green;
	padding: 1rem;
	.item {
		background-color: #fff;
		margin-bottom: 1.5rem;
		padding: 1rem 1rem 2rem;
	}
	.image {
		img {
			width: 100%;
		}
	}
	.title {
		text-transform: uppercase;
		text-align: center;
		padding-top: 0.5rem;
		padding-bottom: 0;
		margin: 0 auto;
		font-family: $header-font-family;
		font-size: 1.4rem;
	}
	.excerpt {
		color: #999;
		font-size: 0.875rem;
		text-align: center;
		
	}
}

.google-map {
	position: relative;
	height: 300px;
	.openmap {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		margin-bottom: 0;
		background-color: $green;
		color: $green;
	}
}

.submit-icon-button {
	background-color: $green;
	color: #fff;
	padding: 0.5rem 1.2rem;
	border-radius: 4px;
	margin-top: 1rem;
	i {
		padding-left: 0.5rem;
		font-size: 3rem;
		vertical-align: middle;
	}
}

.room-type-name {
	text-transform: uppercase;

}

.gmap {
  height: 360px;
}

ul.hotel-facts, ul.features-list {
  list-style-type: initial;
  columns: 3;
  @include phone {
    columns: 2;
  }
  li {
    padding: 0.6rem;
    margin-left: 0.6rem;
  }
}

.promo-belt {
  li {
    padding: 1.5rem;
  }
  img {
    width: 100%;
  }
  .title {
    text-align: center;
    font-size: 1.25rem;
  }
  .excerpt {
    text-align: center;
  }

  .content {
    padding: 0 1rem 1rem;
  }
}

.section-welcome {
  // border-top: solid 8px $green;
  margin-bottom: 3rem;
  h1 {
  	text-align: center;
  	text-transform: uppercase;
  	font-size: 1.6rem;
  	font-weight: 300;
  }
  text-align: center;
  p {
  	font-weight: 300;
  }
}

.section-hotel-facts {
  border-top: solid 8px $red;
  margin-bottom: 3rem;
  .section-title {
  	padding: 2rem 1rem;
  }
}

.section-title {
	&.red, &.purple, &.green {
		margin: 0 0 1rem;;
		padding: 2rem 1rem 1rem;
		color: #fff;
		width: 100%;
		display: block;
	}
	&.red {
		background-color: $red;
	}
	&.purple {
		background-color: $purple;
	}
	&.green {
		background-color: $green;
	}

}

.quick-contact {
  a.icon-button {
    margin-bottom: 0;
  }
  .online-reserve {
    padding-bottom: 0;
  }
}

.section-welcome {
  .location {
    color: #fff;
    a {
        color: #fff
      }
    > div {
      background-color: darken($purple, 10%);
      padding: 1rem;
    }
    > .panel-title {
      background-color: $red;
      margin-bottom: 0;

    }
    > div:last-child {
      background-color: $green;

    }
  }
}


.promo-panels-wrapper {
  border-top: solid 8px $purple;
	.block-title {
		padding: 1rem 1rem 0.5rem;;
		margin: 0;
		background-color: $primary-color;
		color: #fff;;
		// color: $primary-color-text;
	}
  .panel-title {
    background-color: $red;
  }
}

.promo-list-form {
  font-size: 0.8em;
  h3 {
    font-size: 1rem;
  }
  li {
    cursor: pointer;
  }
}

header {
	background-color: $primary-color-dark;
}

.bouncing-rivavi-wrapper {
	width: 150px;
}

.section-icons-belt {
	text-align: center;
}

.icons-belt {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0.5rem;
	background-color: $primary-color-dark;
	color: #fff;
	text-transform: uppercase;
	> li {
		font-size: 0.75rem;
		padding: 0.5rem;
		padding-right: 1rem;
		vertical-align: middle;
		font-weight: 400;
		letter-spacing: 1px;
		img {
			height: 24px;
			width: auto;
			position: relative;
			top: -1px;
			margin-right: 0.25rem;
		}
	}
}

.location-info {
	> div {
		padding: 0.25rem 0.5rem;
		a {
			color: $body-font-color;
		}
		i {
			text-align: right;
			width: 24px;
			margin-right: 0.25rem;
		}
	}
}

.section-location {
	p {
		font-weight: 300;
	}
}

/*# sourceMappingURL=anton.scss.map */


.section-tripadvisor {
	padding: 3rem 1rem;
	text-align: center;
	img {
		width: 240px;
		text-align: center;
		margin: auto;
	}
}

.section-rooms {
	padding: 3rem 1rem;
}

.room-card, .card-image {
	max-width: 300px;
	margin: auto;
	.image {
		position: relative;
		img {
			filter: brightness(50%);
			width: 100%;
		}
		.title {
			text-transform: uppercase;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			font-weight: 700;
		}
	}
	.excerpt {
		background-color: $primary-color-dark;
		color: #fff;
		text-align: center;
		padding: 2rem 0.5rem;
		text-transform: uppercase;
	}
}

.section-latest-blogs {
	padding: 4rem 1rem;
}

.section-share-moments {
	background-color: $primary-color-dark;
	color: #fff;
	padding: 4rem 1rem;
	p {
		margin-bottom: 0;
		font-weight: 300;
	}
	h3 {
		margin: 0;
		text-transform: none;
		font-size: 1.4rem;
		margin-bottom: 3rem;
	}
	a {
		// color: #fff;
	}
	.social {
		p {
			margin-bottom: 1rem;
		}
	}
	.social-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 170px;
		a {
			text-decoration: none;
			border: none;
			// padding: 0.25rem;
			font-size: 24px;
			background-color: #FFF;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			display: block;
			text-align: center;
			margin-right: 0.5rem;
		}
	}
}

.section-newsletter {
	padding: 4rem 1rem;
	max-width: 840px;
	margin: auto;
	.columns {
		padding: 0.15rem;
	}
	input[type="text"] {
		font-size: 0.75rem;
		line-height: 4rem;
		height: 4rem;
		&::placeholder {
			font-size: 0.75rem;
			text-transform: uppercase;
		}
	}
	.button {
		line-height: 3.9rem;
		padding: 0 1rem;
		background-color: #666;
		@include tablet-down {
			width: 100%;
			display: block;
		}
	}
}

.roomtype-slideshow-nav {
	img {
		width: 30px;
	}
}

.news-list {
	> li {
		padding-bottom: 1rem;
	}
}