

@mixin box-shadow-2($args1, $args2) {
    -webkit-box-shadow: $args1, $args2;
    -moz-box-shadow: $args1, $args2;
    box-shadow: $args1, $args2;
}


// Z-levels

.z-depth-1{
    @include box-shadow-2(0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12));
}
.z-depth-1-half{
    @include box-shadow-2(0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15));
}
.z-depth-2, .z-depth-1:hover{
    @include box-shadow-2(0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-3, .z-depth-2:hover{
    @include box-shadow-2(0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-4, .z-depth-3:hover{
    @include box-shadow-2(0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21));
}
.z-depth-5, .z-depth-4:hover{
    @include box-shadow-2(0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22));
}

[class*="z-depth-"] {
  transition: box-shadow 0.25s ease-in-out;
}

// Dividers

.divider {
  height: 1px;
  overflow: hidden;
  background-color: color("grey", "lighten-2");
}


//  Blockquote

blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid color("red", "lighten-3");
}