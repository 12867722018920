input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, input[type="file"], input[type="checkbox"], input[type="radio"], select {
  margin-bottom: 0; }

form .row > .row.collapse {
	margin-bottom: 1rem;
}

.page-new-session {
	padding-bottom: 120px;
}

.icon-bar > * {
	padding: 0;
}

.icon-bar .icon-bar-content {
	padding: 1.25rem;
}

ul, ul li ul {
	list-style: none;
	margin-left: 0;
	margin-bottom: 0;
}

.input-group {
	margin-bottom: 0;
}

.reveal-modal {
	max-width: 100%;
	margin: auto;

	& form {
 	 	padding: 0.5rem;
 	}
}

h1, h2, h3 {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}

h4, h5, h6 {
  margin-top: 0.25rem;
  margin-bottom: 0.1rem;
}

.top-bar-section .dropdown li a.parent-link {
	font-weight: 700;
	display: none;
	visibility: hidden;
}

.sub-nav dd {
	margin-bottom: 0.5rem;
}

button.disabled, button[disabled], .button.disabled, .button[disabled],  {
	background-color: #ccc;
}

button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
	background-color: #bbb;
}

.alert-box {
	overflow: hidden;
	
	&.alert > .close {
		color: #fff;
	}

	> .close, > .close:hover, > .close:focus {
		opacity: 1;

	}	
} 

.i-for-text-button a.button {
	padding: 0.75rem 0 0.7rem;

	> i {
		font-size: 2rem;
	}
}

[class*="block-grid-"] {
  margin: 0;
  // padding-bottom: 0;
}



ol {
	margin: 0;
}